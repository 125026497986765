/**
 * Local storage keys
 */
export const E_LOCAL_STORAGE_KEYS = {
  /**
   * Key for the auto compute setting
   */
  ENABLE_AUTO_COMPUTE: 'ENABLE_AUTO_COMPUTE',
};

export default {
  E_LOCAL_STORAGE_KEYS,
};
